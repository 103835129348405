<template>
  <div class="container">
    <div class="page-title">
      <h3>应用详情
        <el-divider direction="vertical"></el-divider>
        <span @click="$router.go(-1)">返回</span>
      </h3>
    </div>
    <el-card class="box-card" shadow="never">
      <el-row class="row">
        <el-col :span="8"><span>应用名称：</span>{{app.name}}</el-col>
        <el-col :span="8"><span>管理者：</span>{{app.manager}}</el-col>
        <el-col :span="8"><span>应用范围：</span>{{app.app_range}}</el-col>
      </el-row>
      <el-row class="row">
        <el-col :span="8"><span>开发语言：</span>{{app.language}}</el-col>
        <el-col :span="8"><span>开发商：</span>{{app.developer}}</el-col>
        <el-col :span="8"><span>系统状态：</span>{{app.made_inchina}}</el-col>
      </el-row>
      <el-row class="row">
        <el-col :span="8"><span>资金投入：</span>{{app.price}}</el-col>
        <el-col :span="8"><span>维护部门：</span>{{app.main_depart}}</el-col>
        <el-col :span="8"><span>使用部门：</span>{{app.use_depart}}</el-col>
      </el-row>
      <el-row class="row">
        <el-col :span="8"><span>备注：</span>{{app.note}}</el-col>
      </el-row>
    </el-card>
    <div class="tab-wrap">
      <el-button icon="el-icon-plus" type="text"  @click.prevent="addHost" class="add-btn" v-if="activeName != 'map'">添加关联设备</el-button>
      <el-tabs v-model="activeName" type="card" @tab-click="switchTab">
        <el-tab-pane label="关系图谱" name="map">
          <div ref="appChart" style="width:100%;height: 600px"></div>
        </el-tab-pane>
        <el-tab-pane label="关联主机" name="host">
          <hw-ref-appdevice :app-id="app.id" :tid="1" ref="refhost" v-if="activeName=='host'"></hw-ref-appdevice>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
  import RefAppDevice from "../components/pages/RefAppDevice";
  import * as echarts from 'echarts/core';
  import {TitleComponent, TooltipComponent, LegendComponent} from 'echarts/components';
  import {GraphChart} from 'echarts/charts';
  import {CanvasRenderer} from 'echarts/renderers';

  echarts.use(
      [TitleComponent, TooltipComponent, LegendComponent, GraphChart, CanvasRenderer]
  );


  const option = {
    legend: [{
      //selectedMode: 'single'
    }],
    animationDuration: 1500,
    animationEasingUpdate: 'quinticInOut',

    series: [
      {
        type: 'graph',
        //legendHoverLink: false, //启用图例hover联动高亮
        layout: 'force', //采用力引导布局
        data: [],
        links: [],
        categories: [
          {
            "name": "主机"
          },
          {
            "name": "应用"
          }
        ],
        roam: true, //开启缩放和平移
        label: {
          show: true,
          position: 'right',
          formatter: '{b}'
        },
        labelLayout: {
          hideOverlap: true
        },
        //连接线标签
        edgeLabel: {
          show: false,
        },
        force: {
          repulsion: 800,
          edgeLength: [150, 200],
          layoutAnimation: true,
          friction: 0.2, //这个参数能减缓节点的移动速度。取值范围 0 到 1。
        },
        lineStyle: {
          color: 'source',
          //width: 2,
          curveness: 0.1
        },
        tooltip: {
          formatter: '{a}'  //c:数值
        }
      }
    ]
  };

  export default {
    name: "AppView",
    components: {
      hwRefAppdevice: RefAppDevice,
    },
    data() {
      return {
        id: parseInt(this.$route.query.id),
        app: {},
        activeName: 'map',
        loading: false,
        showChart: false,
      }
    },
    created() {
      this.getAppDetail();
    },
    methods: {
      createChart() {
        const myChart = echarts.init(this.$refs.appChart);
        myChart.showLoading();

        let dev = [
          {
            id: "app_" + this.app.id,
            name: this.app.name,
            symbolSize: 50,
            category: 1
          }
        ];
        dev[0].label = {
          show: true,
          fontWeight: 'bold',
          fontSize: '18px'
        }

        this.$get(this.$api.GetAppChart, {id: this.id}).then((res) => {
          myChart.hideLoading();
          let arr = res.nodes;
          let nodes = [...dev, ...arr];
          option.series[0].data = nodes;
          option.series[0].links = res.links;
          myChart.setOption(option);

          //点击显示关联设备
          myChart.off('click');
          myChart.on('click', (param) => {
            if (param.data.host_id) {
              window.open('/#/device/detail?id=' + param.data.host_id);
            }
          });
        });
      },

      clearChart() {
        const myChart = echarts.init(this.$refs.appChart);
        //myChart.clear();
        option.series[0].data = [];
        myChart.setOption(option);
        myChart.dispose(); //销毁实例
      },
      getAppDetail() {
        this.activeName = 'map';
        this.$get(this.$api.GetAppInfo, {id: this.id}).then((res) => {
            this.app = res.data;
            this.createChart();
        });
      },
      switchTab(tab) {
        if (tab.name == 'map') {
          this.$nextTick(()=>{
            this.createChart();
          });
        }
      },
      addHost() {
        if (this.activeName == 'host') {
          this.$refs.refhost.showPop();
        } else if (this.activeName == 'safe') {
          this.$refs.refsafe.showPop();
        } else{
          this.$refs.refother.showPop();
        }
      }
    }
  }
</script>

<style scoped lang="less">
  .row {
    margin-top: 20px;
    padding: 2px 0;
    span {
      color: #888;
    }
    i {
      font-style: normal;
    }
  }
  .box-card{
    margin-right: 10px;
    margin-bottom: 10px;
    .search-form{
      float: right;
      width: 250px;
      margin-top: -5px;
    }
  }
  .tab-wrap{
    position: relative;
    .add-btn{
      position: absolute;
      right: 10px;
      top: 0px;
      z-index: 999;
    }
    .search-form{
      position: absolute;
      right: 0px;
      top: 0px;
      z-index: 999;
    }
  }
</style>
