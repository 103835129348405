<template>
    <div class="container">
        <el-table :data="devlist" v-loading="loading" element-loading-text="加载中..." style="width: 100%">
            <el-table-column label="设备名称" prop="name">
            </el-table-column>
            <el-table-column label="位置">
                <template slot-scope="scope">
                    {{scope.row.room}} {{scope.row.cab}}
                </template>
            </el-table-column>
            <el-table-column label="管理者" prop="manager">
            </el-table-column>
            <el-table-column label="备注" prop="note">
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <span class="row-opt" @click="edit(scope.row)"><el-link type="primary" icon="el-icon-document">编辑</el-link></span>
                    <el-divider direction="vertical"></el-divider>
                    <span class="row-opt" @click="del(scope.row.id)"><el-link type="primary" icon="el-icon-delete">删除</el-link></span>
                </template>
            </el-table-column>
        </el-table>

        <div class="pagenation">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[10, 20, 50]"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>
        </div>

        <el-dialog :title="popTitle" :visible.sync="addPop" :close-on-click-modal="false">
            <hw-appdev-form :app-id="appId" :mode="mode" :tid="tid" :id="refId" @closePop="closePop"></hw-appdev-form>
        </el-dialog>
    </div>
</template>

<script>
    import AppdevForm from "./AppdevForm";

    export default {
        name: "RefApphost",
        components: {
            hwAppdevForm: AppdevForm,
        },
        props: {
            devId: {
                type: Number
            },
            appId: {
                type: Number
            },
            tid: {
                type: Number
            },
        },
        data() {
            return {
                loading: true,
                devlist: [],
                currentPage: 1,
                pageSize: 10,
                total: 0,
                addPop: false,
                refId: null,
                mode: 'add',
                popTitle: '添加关联设备',
            }
        },
        mounted() {
            this.getMapList();
        },
        methods: {
            //关联设备列表
            getMapList() {
                this.$get(this.$api.GetDevappMapList, {app_id: this.appId, tid: this.tid, limit: 10}).then((res) => {
                    this.loading = false;
                    this.devlist = res.data.list;
                    this.total = res.data.total_count;
                });
            },
            handleCurrentChange (val) {
                this.currentPage = val
                this.getMapList()
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.getMapList()
            },
            del(id) {
                this.$confirm('此操作将删除该设备吗, 是否继续?', '提示', {
                    type: 'warning'
                }).then(() => {
                    this.$post(this.$api.DeleteDevappMap, {id: id}).then(() => {
                        this.$message({
                            message: '操作成功！',
                            type: 'success'
                        });
                        this.getMapList();
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            edit(row) {
                this.popTitle = '编辑关联设备';
                this.addPop = true;
                this.mode = 'edit';
                this.refId = row.id;
            },
            showPop() {
                this.addPop = true;
            },
            closePop() {
                this.addPop = false;
                this.popTitle = '新增关联设备';
                this.refId = null;
                this.mode = 'add';
                this.getMapList();
            }
        }
    }
</script>

<style scoped lang="less">
    .pagenation{
        margin: 20px auto;
        text-align: center;
    }
</style>
